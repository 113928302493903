import { JsonConvert, JsonObject, JsonProperty } from 'json2typescript'
import { HubtypeCase } from '../../models/hubtype-case'
import { HubtypeMessage } from '../../models/hubtype-message'
import { JsonFor } from '../../utils/json-utils'

export class CaseQuery {
  constructor(
    public query: string | null,
    public start_date: Date,
    public end_date: Date,
    public projects: string[] | null,
    public queues: string[] | null,
    public agents: string[] | null,
    public statuses: string[],
    public providers: string[],
    public contactReasons: string[],
    public ratings: number[]
  ) {}
}

const jsonConvert: JsonConvert = new JsonConvert()

@JsonObject
class MessageSearchResult {
  @JsonProperty('id', String)
  id: string

  // Text highlighted excerpt. Eg "is an <em>awesome</em> car
  @JsonProperty('summary', String)
  summary: string

  // Only the shortest substrings that match the query.
  @JsonProperty('highlights', [String])
  highlights: string[]

  @JsonProperty('score', Number)
  score: number
}

@JsonObject
export class SearchedMessage {
  @JsonProperty('message', HubtypeMessage)
  public message: HubtypeMessage
  @JsonProperty('search_result', MessageSearchResult)
  public search_result: MessageSearchResult
}

@JsonObject
export class SearchedCase {
  /** The summary of the matched message with highest score.
   See MessageHighlight.summary */
  @JsonProperty('best_message_summary', String)
  best_message_summary = ''

  // Sorted by score starting by best
  @JsonProperty('message_results', [MessageSearchResult])
  message_results: MessageSearchResult[] = []
}

@JsonObject
export class ArchiveCase {
  @JsonProperty('case', HubtypeCase)
  public case: HubtypeCase = undefined

  @JsonProperty('searched', SearchedCase)
  public searched: SearchedCase = new SearchedCase()
}

/**
 * Before providing highlights, HubtypeCasePaginator & archive cases contained plain HubtypeCase instead of ArchiveCase
 * Remove after some time (browser localstorage will contain initially  HubtypeCase's)
 */
export class BackwardCompatCase {
  public static fixPaginator(
    json: JsonFor<HubtypeCasePaginator>
  ): JsonFor<HubtypeCasePaginator> {
    json.results = BackwardCompatCase.fromCases(json.results)
    return json
  }

  static fromCase(c: JsonFor<ArchiveCase>): JsonFor<ArchiveCase> {
    if (!BackwardCompatCase.isAPlainCase(c)) {
      return c
    }
    const ac = new ArchiveCase()
    ac.case = jsonConvert.deserializeObject(
      c as any as HubtypeCase,
      HubtypeCase
    )
    const ser = jsonConvert.serialize(ac)
    return ser
  }

  static fromCases(cases: JsonFor<ArchiveCase[]>): JsonFor<ArchiveCase[]> {
    return cases.map(c => BackwardCompatCase.fromCase(c))
  }

  private static isAPlainCase(c: JsonFor<ArchiveCase>): boolean {
    return c['created_at'] != null
  }
}

@JsonObject
export class HubtypeCasePaginator {
  @JsonProperty('next', String)
  next: string = undefined
  @JsonProperty('previous', String)
  previous: string = undefined
  /** Old version of backend will send [HubtypeCase]. CaseService will convert it*/
  @JsonProperty('results', [ArchiveCase])
  results: ArchiveCase[] = undefined
}
